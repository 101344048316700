import Vue from "vue";
import App from "./App.vue";
import router from "./router";

//ElementUI imports
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "element-ui/lib/theme-chalk/display.css";

//Flicking imports
import VueFlicking from "@egjs/vue-flicking";
import "@egjs/vue-flicking/dist/flicking.css";
import "@egjs/flicking-plugins/dist/flicking-plugins.css";

import "./styles/main.css";
Vue.config.productionTip = false;

//plugins
Vue.use(ElementUI);
Vue.use(VueFlicking);

//Vue Instance
new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
