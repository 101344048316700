var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"tabs-wrapper",class:{
    'better-padding': _vm.betterPadding,
    'is-button': _vm.isButton,
    'background-active': _vm.isActive && _vm.isButton,
    'home-tabs': _vm.isHomeTabs,
  }},[_c('div',{staticClass:"indicator-bar",class:{
      'indicator-active': _vm.isActive,
      'indicator-right': _vm.isRight,
      'indicator-rounded': _vm.isButton,
    }}),_c('div',{staticClass:"text-wrapper",class:{
      'text-active': _vm.isActive,
      'big-font': _vm.bigFont,
      'home-tab-font': _vm.isHomeTabs,
      'is-button-font': _vm.isButton,
    }},[_vm._v(" "+_vm._s(_setup.props.title)+" ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }