<template>
  <nav>
    <div class="nav-wrapper">
      <router-link to="/">
        <div class="nav-logo-inner">
          <img src="../assets/huiNianLogo.png" alt="logo" />
          <div>慧年科技</div>
        </div>
      </router-link>
      <div class="nav-main-wrapper" v-show="show">
        <router-link to="/solution1" class="dropdown-link">
          <div class="nav-link-wrapper dropdown">
            <p>解决方案</p>
            <div
              class="indicator-bar"
              :class="{
                'bar-is-active': isActive('solution1') || isActive('solution2'),
              }"
            ></div>
          </div>
        </router-link>
        <div class="dropdown-content">
          <el-row :gutter="0">
            <el-col :span="4">
              <div class="grid-content bg-green">
                <div class="spacer1"></div>
                <router-link to="/solution1"
                  ><homeViewTabs
                    title="面向政府"
                    @mouseover.native="dropdownTab = '面向政府'"
                    :isActive="dropdownActive('面向政府')"
                    betterPadding
                    isButton
                    bigFont
                /></router-link>

                <div class="spacer2"></div>
                <router-link to="solution2"
                  ><homeViewTabs
                    title="面向企业"
                    @mouseover.native="dropdownTab = '面向企业'"
                    :isActive="dropdownActive('面向企业')"
                    betterPadding
                    isButton
                    bigFont
                /></router-link>
              </div>
            </el-col>
            <el-col :span="20">
              <div class="grid-content solution-content">
                <!-- tab level -->
                <template v-for="tab in navSolutionTabData">
                  <div
                    v-if="showSolutionContent(tab.name)"
                    :key="tab.name"
                    class="solution-tab"
                  >
                    <el-row :gutter="20">
                      <!-- column level -->
                      <el-col v-for="col in tab.col" :key="col.index" :span="8">
                        <div class="solution-column">
                          <el-row>
                            <!-- row level -->
                            <el-col
                              v-for="content in col.content"
                              :key="content.title"
                              :span="24"
                              class="solution-row"
                            >
                              <router-link to="/register" target="_blank">{{
                                content.title
                              }}</router-link>
                            </el-col>
                          </el-row>
                        </div>
                      </el-col>
                    </el-row>
                  </div>
                </template>
              </div>
            </el-col>
          </el-row>
        </div>

        <router-link to="/examples"
          ><div class="nav-link-wrapper">
            <p>优秀案例</p>
            <div
              class="indicator-bar"
              :class="{ 'bar-is-active': isActive('examples') }"
            ></div></div
        ></router-link>
        <router-link to="/news"
          ><div class="nav-link-wrapper">
            <p>新闻动态</p>
            <div
              class="indicator-bar"
              :class="{ 'bar-is-active': isActive('news') }"
            ></div></div
        ></router-link>
        <router-link to="/about"
          ><div class="nav-link-wrapper">
            <p>关于我们</p>
            <div
              class="indicator-bar"
              :class="{ 'bar-is-active': isActive('about') }"
            ></div></div
        ></router-link>
      </div>
      <div class="right-wrapper" v-show="show">
        <router-link to="/register" target="_blank">预约演示</router-link>
      </div>
    </div>
  </nav>
</template>
<script setup>
import { watch, ref } from "vue";
import { useRoute } from "vue-router/composables";
import homeViewTabs from "./HomeViewComponents/homeViewTabs.vue";
import { navSolutionTabData } from "@/data/data.js";

const route = useRoute();
const show = ref(true); // change to false if want to hide on initial load
const currentTab = ref("");
const dropdownTab = ref("面向政府");

const isActive = (name) => {
  return currentTab.value === name;
};

const dropdownActive = (name) => {
  return dropdownTab.value === name;
};

const showSolutionContent = (name) => {
  return dropdownTab.value === name;
};

watch(route, (newValue) => {
  show.value = newValue.name === "register" ? true : true; // change here if want to show/hide nav
  currentTab.value = newValue.name;
});
</script>

<style scoped>
.right-wrapper a {
  color: var(--color-button-green);
  background: #fff;
  border: 0.0625rem /* 1/16 */ solid var(--color-button-green);
  font-size: 1.0625rem /* 17/16 */;
  padding: 0.4375rem /* 7/16 */ 0.75rem /* 12/16 */;
  font-weight: normal;
  margin: 0.375rem /* 6/16 */ 0;
  margin-right: 0.75rem /* 12/16 */;
  display: inline-block;
  text-decoration: none;
  font-family: "Open Sans", sans-serif;
  min-width: 7.5rem /* 120/16 */;
  border-radius: 0.25rem /* 4/16 */;
  transition: all 0.2s;
}

.right-wrapper a:hover {
  cursor: pointer;
  background-color: var(--color-button-green-hover);
  color: #fff;
  transition: all 0.2s;
}
.right-wrapper {
  margin-left: auto;
  margin-right: 3vw;
}
.nav-wrapper {
  display: flex;
  justify-content: left;
  align-items: center;
  width: 70vw;
  min-width: 1200px;
  max-width: 1200px;
  height: 100%;
}
.nav-logo-inner {
  display: flex;
  justify-content: center;
  align-items: center;
}
.nav-logo-inner img {
  height: 2.1rem;
}

.nav-logo-inner div {
  margin-left: 0.75rem /* 12/16 */;
}

nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-width: 1200px;
  z-index: 9999 !important;
  display: flex;
  font-size: 0.875rem /* 14/16 */;
  box-shadow: 0rem /* 0/16 */ 0.125rem /* 2/16 */ 0.3125rem /* 5/16 */ 0rem
    /* 0/16 */ rgba(0, 0, 0, 0.05);
  background-color: var(--color-background);
  justify-content: center;
  height: 4.5625rem /* 73/16 */;
}

nav a {
  font-weight: bold;
  color: var(--color-text-mute);
  text-decoration: none;
  transition: color 0.3s;
}

nav a:hover {
  color: var(--green-500);
  transition: color 0.3s;
}

.nav-main-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4.5625rem /* 73/16 */;
  min-height: 4.5625rem /* 73/16 */;
}

.nav-main-wrapper a.router-link-exact-active {
  color: #42b983;
}

.nav-main-wrapper {
  height: fit-content;
}

.nav-main-wrapper a {
  margin-left: 4.125rem /* 66/16 */;
}

.nav-link-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 4.5625rem /* 73/16 */;
  padding: 0;
  margin: 0;
  position: relative;
}

.indicator-bar {
  width: 0%;
  height: 0.3125rem /* 5/16 */;
  position: absolute;
  bottom: 0;
  left: 0;
  transition: all 0.3s ease-in-out;
}

nav a:hover .indicator-bar {
  background-color: var(--green-600);
  width: 100%;
  transition: all 0.3s ease-in-out;
}

.bar-is-active {
  background-color: var(--green-600);
  width: 100%;
  transition: background-color 0.2s;
}

/* dropdown */
.dropdown-content {
  display: none;
  top: 4.5625rem /* 73/16 */;
  left: 0;
  position: absolute;
  background-color: var(--white-100);
  width: 100%;
  height: 50vh;
  min-height: 400px;
  box-shadow: 0rem /* 0/16 */ 0.5rem /* 8/16 */ 1rem /* 16/16 */ 0rem /* 0/16 */
    rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-link:hover ~ .dropdown-content,
.dropdown-content:hover {
  display: block;
}

.dropdown-content {
  cursor: default;
}

.el-row {
  margin-bottom: 1.25rem /* 20/16 */;
  &:last-child {
    margin-bottom: 0;
  }
}
.el-col {
  border-radius: 0.25rem /* 4/16 */;
}
.bg-green {
  background: var(--green-700);
}
.grid-content {
  min-height: 400px;
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
}

.spacer1 {
  height: 3.125rem /* 50/16 */;
}

.spacer2 {
  height: 1.875rem /* 30/16 */;
}

.solution-content {
  justify-content: center;
  align-items: start;
}

.solution-tab {
  width: 80%;
  height: 90%;
  padding-top: 1.25rem /* 20/16 */;
  margin-left: 1.25rem /* 20/16 */;
}

.solution-column {
  height: 25vh;
}

.solution-row {
  display: flex;
  justify-content: start;
  align-items: start;
  margin-left: 0;
  margin-top: 1.5rem /* 20/16 */;
}

.solution-row a {
  text-decoration: none;
  color: var(--black-300);
  text-align: left;
  letter-spacing: 1px;
  font-size: 1.3rem;
  margin-top: 20px;
}

@media (-webkit-min-device-pixel-ratio: 1.25) {
  .solution-content {
    zoom: 0.8;
  }
  .spacer1,
  .spacer2 {
    zoom: 0.8;
  }
}

@media (-webkit-min-device-pixel-ratio: 1.5) {
  .solution-content {
    zoom: 0.66;
  }
  .spacer1,
  .spacer2 {
    zoom: 0.66;
  }

  .dropdown-content,
  .grid-content {
    min-height: 300px;
  }
}
</style>
