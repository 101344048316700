<template>
  <div class="card-container">
    <el-row :gutter="80">
      <el-col
        :span="8"
        v-for="(item, index) in cardData"
        :key="index"
        class="custom-col"
      >
        <el-card
          :body-style="{ padding: '0rem /* 0/16 */', height: '100%' }"
          class="hover-card"
        >
          <div class="card-content">
            <img
              v-if="item.type === 'image'"
              :src="item.content"
              class="image"
              alt="Card Image"
            />
            <div v-else class="text-content">
              {{ item.content }}
            </div>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      cardData: [
        { type: "image", content: require("@/assets/industryPartner1.png") },
        { type: "image", content: require("@/assets/industryPartner2.png") },
        { type: "image", content: require("@/assets/industryPartner3.png") },
        { type: "text", content: "重庆德馨养老服务有限公司" },
        { type: "image", content: require("@/assets/industryPartner4.png") },
        { type: "image", content: require("@/assets/industryPartner5.png") },
        // Add more items with different types and content
      ],
    };
  },
};
</script>

<style scoped>
.card-container {
  max-width: 80vw;
}

.card-content {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 6.25rem /* 100/16 */;
  min-width: 6.25rem /* 100/16 */;
}

.image {
  width: 80%;
  height: 100%;
  object-fit: cover; /* Ensure the image covers the entire container */
}

.text-content {
  text-align: center;
  font-size: 1rem /* 32/16 */;
}

.hover-card {
  transition: transform 0.3s ease-in-out;
  width: 100%;
  height: 100%;
}

.hover-card:hover {
  transform: scale(1.05);
}

.custom-col {
  margin-bottom: 1.25rem /* 20/16 */;
}

.el-row {
  min-width: 1200px;
}
</style>
