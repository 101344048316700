<template>
  <div class="home-wrapper">
    <!-- Hero Section -->
    <div class="hero-outer-wrapper">
      <div class="hero-wrapper">
        <div class="hero-text-wrapper">
          <transition name="fade" @before-enter="beforeEnter" @enter="enter">
            <div class="hero-text" v-if="showText">
              <h1>
                用智<span :style="{ color: 'var(--color-text-green)' }">慧</span
                >科技
              </h1>
              <h1>
                让老<span :style="{ color: 'var(--color-text-green)' }">年</span
                >生活更幸福
              </h1>
              <p>Empowering Lives of Elderly Through Smart Technology</p>
            </div>
          </transition>
        </div>
      </div>
    </div>

    <!-- grid-card Section -->
    <div class="grid-outer-wrapper">
      <div class="grid-section-wrapper">
        <div class="grid-title-wrapper title-wrapper">
          <h1>面向丰富养老场景的全方位解决方案</h1>
        </div>
        <div class="grid-card-wrapper"><gridCards /></div>
      </div>
    </div>

    <!-- Information Section -->
    <div class="info-outer-wrapper">
      <div class="info-section-wrapper">
        <div class="information-title-wrapper title-wrapper">
          <h1>优秀创新案例</h1>
        </div>
        <div class="info-body-wrapper">
          <el-row :gutter="20" class="info-row">
            <el-col :span="4">
              <div class="info-tabs-wrapper grid-content">
                <div class="spacer"></div>
                <homeViewTabs
                  v-for="title in titles"
                  @click.native="handleTabCick(title)"
                  :title="title"
                  :key="title"
                  :isActive="currentTab === title ? true : false"
                  isHomeTabs
                />
              </div>
            </el-col>
            <el-col :span="20">
              <div class="info-content-wrapper grid-content">
                <div v-show="false" class="content-indicator-wrapper">
                  <div
                    class="content-indicator"
                    :style="{ width: indicatorWidth }"
                  ></div>
                </div>
                <transition name="fader" mode="out-in">
                  <template v-for="(tab, index) in homeTabsContent">
                    <tabContent
                      v-if="showTab(tab.name)"
                      :key="index"
                      :title="tab.title"
                      :img="tab.img"
                      :content="tab.content"
                      :isVideo="tab.isVideo"
                      :tabName="tab.tabName"
                    />
                  </template>
                </transition>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>

    <!-- News Section -->
    <!-- <div class="news-section-wrapper">
      <div class="news-title-wrapper title-wrapper">
        <h1>优秀创新案例</h1>
      </div>
      <newsCarousel />
    </div> -->

    <!-- Partners Section -->
    <div class="partner-outer-wrapper">
      <div class="partners-section-wrapper">
        <div class="partners-title-wrapper">
          <h1>养老行业合作伙伴</h1>
        </div>
        <div class="partners-body-wrapper">
          <industryPartnerCard />
        </div>
      </div>
    </div>

    <!-- Bottom Banner -->
    <div class="bottom-wrapper"><enquiryBar /></div>
  </div>
</template>
<script setup>
import homeViewTabs from "../components/HomeViewComponents/homeViewTabs.vue";
import industryPartnerCard from "@/components/HomeViewComponents/industryPartnerCard.vue";
import tabContent from "@/components/HomeViewComponents/tabContent.vue";
import { homeTabsContent } from "@/data/data.js";
import gridCards from "@/components/HomeViewComponents/gridCards.vue";
import enquiryBar from "@/components/enquiryBar.vue";
// import newsCarousel from "@/components/HomeViewComponents/newsCarousel.vue";

import { ref, onMounted, onBeforeUnmount } from "vue";

const titles = ["优秀案例1", "优秀案例2", "优秀案例3"];
const currentTab = ref("优秀案例1");
const currentTabIndex = ref(0);
const currentTime = ref(0);
const indicatorWidth = ref("");
const MAX_TIME = 100;
const TIMEOUT_DURATION = 10;

const showTab = (name) => {
  return currentTab.value === name ? true : false;
};

const showText = ref(false);

const beforeEnter = (el) => {
  el.style.transform = "translateY(30px)";
  el.style.opacity = 0;
};

const enter = (el, done) => {
  el.offsetHeight; // Trigger reflow
  el.style.transition = "transform 1s ease-out, opacity 1s ease-out";
  el.style.transform = "translateY(0)";
  el.style.opacity = 1;
  done();
};

const calculateWidth = (paused) => {
  if (paused) {
    indicatorWidth.value = "0%";
    return;
  }
  const percentage = (currentTime.value / MAX_TIME) * 100;
  // console.log(Math.floor(percentage).toString() + "%");
  indicatorWidth.value = percentage.toString() + "%";
};

let paused = false;
let timeout = 0;

const handleTabCick = (title) => {
  currentTab.value = title;
  currentTabIndex.value = titles.indexOf(title);
  paused = true;
  timeout = 0;
  currentTime.value = 0;
};

const intervalTab = setInterval(() => {
  calculateWidth(paused);
  // console.log(currentTime.value);
  // console.log(timeout);
  if (currentTime.value >= MAX_TIME || paused) {
    if (!paused) {
      currentTabIndex.value = (currentTabIndex.value + 1) % 3;
      currentTime.value = 0;
      currentTab.value = titles[currentTabIndex.value];
    }
    paused = true;
    timeout++;
    if (timeout >= TIMEOUT_DURATION) {
      timeout = 0;
      paused = false;
    }
  } else {
    currentTime.value++;
  }
}, 100);

// Trigger the animation when the component is mounted
onMounted(() => {
  showText.value = true;
});

onBeforeUnmount(() => {
  clearInterval(intervalTab);
});
</script>
<style scoped>
.home-wrapper {
  min-width: 1200px;
}
/* Hero Section */
.hero-outer-wrapper {
  width: 100%;
  margin-top: 7vh;
}

.hero-wrapper {
  width: 100%;
  min-width: 1200px;
  height: 658px;
  min-height: 658px;
  background-image: url("../assets/heroBanner.png");
  background-size: cover;
  background-position: center;
}

.hero-text-wrapper {
  margin: 0 auto;
  width: 70vw;
  min-width: 1200px;
  max-width: 1200px;
  height: 658px;
  min-height: 658px;
  display: flex;
  justify-content: start;
  align-items: center;
}
.hero-text p {
  color: var(--color-text-mute);
  margin: 0;
}
.hero-text h1 {
  letter-spacing: 0.1875rem /* 3/16 */;
  margin: 0;
  font-size: 3rem;
}

.hero-text {
  width: fit-content;
  top: 0;
  left: 0;
  text-align: left;
  transition: transform 1s ease-out, opacity 1s ease-out;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.hero-image {
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 7vh;
  z-index: -1;
}

/* Grid Section */
.grid-outer-wrapper {
  width: 100%;
}
.grid-section-wrapper {
  width: 70vw;
  min-width: 1200px;
  max-width: 1200px;
  height: fit-content;
  margin: 0 auto;
  /* margin-top: -200px; */
}

.grid-title-wrapper {
  margin-top: 5xvh;
}

/* Information Section */
.info-outer-wrapper {
  width: 100%;
  background-image: url("../assets/informationBackground.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.info-section-wrapper {
  width: 70vw;
  min-width: 1200px;
  max-width: 1200px;
  margin: 0 auto;
  height: fit-content;
  padding-bottom: 5vh;
  padding-top: 5vh;
}

.information-title-wrapper {
  margin-top: 2vh;
  margin-bottom: 2vh;
}

.info-body-wrapper {
  margin-bottom: 6vh;
}

/* .info-row {
  min-width: 1200px;
} */

.grid-content {
  background: linear-gradient(
    180deg,
    rgba(239, 239, 244, 0.6) 0%,
    rgba(205, 240, 229, 0.6) 100%
  );
  box-shadow: inset 0rem /* 0/16 */ -0.125rem /* 2/16 */ 0.1875rem /* 3/16 */ 0rem
      /* 0/16 */ rgba(185, 185, 185, 0.5),
    inset 0rem /* 0/16 */ 0.125rem /* 2/16 */ 0.1875rem /* 3/16 */ 0rem
      /* 0/16 */ rgba(85, 187, 133, 0.2);
  border-radius: 0.625rem /* 10/16 */;
  height: 700px;
}

.content-indicator-wrapper {
  height: 8px;
  width: 100%;
  position: relative;
}

.content-indicator {
  height: 7px;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: var(--green-400);
  margin-top: 5px;
  opacity: 0.5;
  border-radius: 6px 0 0 0;
  transition: width 0.3s;
}

.spacer {
  height: 30px;
}

.fader-enter-active,
.fader-leave-active {
  transition: opacity 0.3s ease;
}

.fader-enter-from,
.fader-leave-to {
  opacity: 0;
}

/* News Section */
.news-section-wrapper {
  max-width: 80vw;
  width: 60vw;
  height: fit-content;
}

/* Partners Section */
.partner-outer-wrapper {
  width: 100%;
}
.partners-section-wrapper {
  width: 70vw;
  min-width: 1200px;
  max-width: 1200px;
  margin: 0 auto;
  height: fit-content;
}

.partners-body-wrapper {
  margin-bottom: 5vh;
}

.bottom-wrapper {
  height: fit-content;
  width: 100%;
}

@media (-webkit-min-device-pixel-ratio: 1.25) {
  .info-content-wrapper,
  .info-tabs-wrapper {
    zoom: 0.8;
  }

  /* .grid-section-wrapper {
    margin-top: -27vh;
  } */

  .grid-content {
    min-height: 770px;
  }
}

@media (-webkit-min-device-pixel-ratio: 1.5) {
  .info-content-wrapper,
  .info-tabs-wrapper {
    zoom: 0.66;
  }

  .hero-text-wrapper h1,
  .hero-text-wrapper p {
    padding-left: 100px;
  }

  /* .grid-section-wrapper {
    margin-top: -35vh;
  } */

  .info-section-wrapper {
    width: 50vw;
    min-width: 1000px;
  }

  .grid-content {
    min-height: 800px;
  }
}
</style>
