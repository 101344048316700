import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/register",
    name: "register",
    component: () => import("../views/RegistrationView.vue"),
  },
  {
    path: "/about",
    name: "about",
    component: () => import("../views/AboutView.vue"),
  },
  {
    path: "/news",
    name: "news",
    component: () => import("../views/NewsView.vue"),
  },
  {
    path: "/solution1",
    name: "solution1",
    component: () => import("../views/SolutionsView.vue"),
  },
  {
    path: "/solution2",
    name: "solution2",
    component: () => import("../views/smeSolutionView.vue"),
  },
  {
    path: "/examples",
    name: "examples",
    component: () => import("../views/ExamplesView.vue"),
  },
  {
    path: "*",
    component: HomeView,
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: function (to, _from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { el: to.hash, behavior: "smooth" };
    } else {
      // console.log("moving to top of the page");
      window.scrollTo(0, 0);
    }
  },
});

export default router;
